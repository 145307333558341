import translations from '../sk.json';

window.I18n.store(translations);
window.I18n.default_locale = 'sk';
window.I18n.locale = 'sk';
window.I18n.pluralization['sk'] = function (count) {
  var key = count === 1 ? 'one' : [2, 3, 4].indexOf(count) >= 0 ? 'few' : 'other'
  return [key]
}

/* satisfy vite compiler  */
export default {};